/** @typedef {ReturnType<state>} State */
/** @typedef {Record<string, (state: State, data) => void>} Mutation */

export const state = () => ({
  configs: {
    timeFrames: [],
    availableCurrencies: [],
    currentTimeFrame: null
  }
});

export const getters = {
}

/**
 * @type {Mutation}
 */
export const mutations = {
  SET_TIME_FRAMES(state, payload) {
    state.configs.timeFrames = [...payload];
  },
  SET_AVAILABLE_CURRENCIES(state, payload) {
    state.configs.availableCurrencies = [...payload];
  },
  SET_CURRENT_TIME_FRAME(state, payload) {
    state.configs.currentTimeFrame = { ...payload }
  }
};

export const actions = {
  async getTimeFrames({ commit }, params = {}) {
    commit('SET_LOADING', true, { root: true });
    try {
      const res = await this.$axios.get(`/timeFrames`, { params });
      commit('SET_TIME_FRAMES', res.data);

      return res.data || [];

    } catch (err) {
      console.error(err);
    } finally {
      commit('SET_LOADING', false, { root: true });
    }
  },
  async createTimeFrame({ commit, dispatch }, data) {
    commit('SET_LOADING', true, { root: true });
    try {
      const res = await this.$axios.post(`/timeFrames`, data);
      await dispatch('getTimeFrames');

      return res.data;
    } catch (err) {
      commit("user/SHOW_MESSAGE", {
        color: "error",
        message: "Sembra che ci sia già un blocco temporale creato con le stesse date",
      }, { root: true });
      console.error(err);
      return null;
    } finally {
      commit('SET_LOADING', false, { root: true });
    }
  },
  async patchTimeFrame({ commit, state, dispatch }, { id, ...data }) {
    commit('SET_LOADING', true, { root: true });
    try {
      const res = await this.$axios.patch(`/timeFrames/${id}`, data);
      await dispatch('getTimeFrames');
      return res.data;
    } catch (error) {
      console.error(error);
      return null;
    } finally {
      commit('SET_LOADING', false, { root: true });
    }
  },

  async getAvailableCurrencies({ commit }, params = {}) {
    try {
      const res = await this.$axios.get(`/currencies`, { params });
      const data = res.data || [];
      commit('SET_AVAILABLE_CURRENCIES', data);
      return data;
    } catch (err) {
      console.error(err);
    }
  }
};
