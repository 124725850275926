export default function moduleCheck(moduleName) {
    return async function ({ store }) {
        await store.dispatch('settings/getDinkSubscriptions', { organizationID: store.state.localStorage.staffMember.organization.id });

        const module = store.getters[`settings/${moduleName}`];

        if (module?.showAlertModal) {
            store.commit('settings/SET_DINK_SUBSCRIPTION_MODAL', {
                show: true,
                modalData: {
                    module: moduleName,
                    active: module.enabled,
                    persistent: module.modalParams?.persistent,
                    status: module.modalParams?.status
                },
            });
        }
    };
}