
import BaseModal from "./BaseModal.vue";
export default {
  components: { BaseModal },
  props: {
    showDinkModuleAlert: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    modalParams() {
      return this.$store.state.settings.modalData?.data;
    },
  },
  methods: {
    closeModalAndNavigate(path) {
      this.$emit("closeModal");
      this.$router.push({ path });
    },
  },
};
